import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import SeoDataHandler from "utils/SeoDataHandler";

import { SeoBE, SeoFE } from "types/SharedType";

import SolutionApi from "api/SolutionApi";

import { NotificationContext } from "providers/notificationProvider";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";

import WhitePaperForm from "./components/whitePaperForm";
import WhitePaperGetStarted from "./components/whitePaperGetStarted";

interface WhitePaperPageDetailBE {
	seo: SeoBE;
	white_paper_page_detail: {
		tag: string;
		title: string;
		description: string;
	};
}

interface WhitePaperPageDetailFE {
	tag: string;
	title: string;
	description: string;
}

const WhitePaper = () => {
	const { id } = useParams();
	const { i18n } = useTranslation();
	const { displayNotification } = useContext(NotificationContext);

	const [seo, setSEO] = useState<SeoFE>();
	const [pageDetail, setPageDetail] = useState<WhitePaperPageDetailFE>();

	const solutionApi = SolutionApi();
	const seoDataHandler = SeoDataHandler();

	const handleData = (data: WhitePaperPageDetailBE) => {
		return {
			tag: data?.white_paper_page_detail?.tag,
			title: data?.white_paper_page_detail?.title,
			description: data?.white_paper_page_detail?.description,
		};
	};

	const getSolution = async () => {
		try {
			const solutionData: WhitePaperPageDetailBE =
				await solutionApi.getSolutionData(
					i18n.language,
					id,
					"white_paper_page_detail"
				);
			const detail = handleData(solutionData);
			const seo = seoDataHandler.handleSeoData(solutionData.seo);

			seo && setSEO(seo);
			detail && setPageDetail(detail);
		} catch (error) {
			console.log(error);
			displayNotification(
				"Something Went Wrong When Handling WhitePaper, Please Try Again !",
				"error"
			);
		}
	};

	useEffect(() => {
		getSolution();
		// eslint-disable-next-line
	}, [id, i18n.language]);

	return (
		<>
			{seo && (
				<SeoComponent
					metaTitle={seo?.metaTitle + "| whitepaper"}
					metaDescription={`${pageDetail?.title}`}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
				/>
			)}

			<Nav />

			{pageDetail && (
				<WhitePaperForm
					tag={pageDetail?.tag}
					title={pageDetail?.title}
					description={pageDetail?.description}
				/>
			)}

			<WhitePaperGetStarted />

			<Footer />
		</>
	);
};

export default WhitePaper;
