import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Nav from "components/layout/nav";
import HeroBanner from "components/layout/heroBanner";
import SeoComponent from "components/common/seoComponent/SeoComponent";
import SimpleText from "components/layout/simpleText";
import Numbers from "components/layout/numbers";
import Footer from "components/layout/footer";
import GetInTouchCmp from "components/layout/getInTouchCmp";

import Ceo from "./components/ceo";
import AboutUsDescription from "./components/aboutUsDescription";
import OurHistory from "./components/ourHistory";
import WeArePasionate from "./components/weArePasionate";
import TextTestimonies from "components/layout/textTestimonies";

import { NotificationContext } from "providers/notificationProvider";

import PageDataHandler from "utils/PageDataHandler";

import { PageInfo } from "types/SharedType";

import "./AboutUs.scss";

const AboutUs = () => {
	const { t, i18n } = useTranslation("aboutUs");
	const { displayNotification } = useContext(NotificationContext);

	const [pageInfo, setPageInfo] = useState<PageInfo>();
	const [isEmpty, setIsEmpty] = useState<boolean>(true);

	const pageDataHandler = PageDataHandler();

	const onFetch = (isEmpty: boolean) => {
		setIsEmpty(isEmpty);
	};

	const getPageData = async (pageName: string, locale: string) => {
		try {
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);

			pageInfo && setPageInfo(pageInfo);
		} catch (error) {
			console.error(`[Error - API] Error calling ${pageName} data`, error);
			displayNotification(
				`Something Went Wrong Fetching ${pageName} Data, Please Try Again !`,
				"error"
			);
		}
	};

	useEffect(() => {
		getPageData("about-us-page", i18n.language);
		// eslint-disable-next-line
	}, [i18n.language]);

	return (
		<>
			<Nav />

			{pageInfo && (
				<>
					<SeoComponent
						metaTitle={pageInfo?.seo?.metaTitle}
						metaDescription={pageInfo?.seo?.metaDescription}
						metaImage={pageInfo?.seo?.metaImage}
						metaSocial={pageInfo?.seo?.metaSocial}
						canonicalURL={pageInfo?.seo?.canonicalURL}
						keywords={pageInfo?.seo?.keywords}
						metaRobots={pageInfo?.seo?.metaRobots}
						metaViewport={pageInfo?.seo?.metaViewport}
						structuredData={pageInfo?.seo?.structuredData}
					/>
				</>
			)}

			<HeroBanner
				picture={pageInfo?.detail?.bannerImage?.url}
				title={pageInfo?.detail?.title}
				description={pageInfo?.detail?.description}
			/>

			<SimpleText withBubbles={true}>
				<h2 className="intro-title">{t("introTitle")}</h2>

				<p className="intro-description">{t("introDescription")}</p>
			</SimpleText>

			<Ceo />

			<AboutUsDescription />

			<OurHistory />

			<WeArePasionate />

			<Numbers />

			<div className={`about-us-testimonies-container ${!isEmpty && "show"}`}>
				<TextTestimonies onFetch={onFetch} />
			</div>

			<GetInTouchCmp />

			<Footer />
		</>
	);
};

export default AboutUs;
