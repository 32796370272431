import { Helmet } from "react-helmet-async";
import { SeoFE } from "types/SharedType";

const SeoComponent = ({
	metaTitle,
	metaDescription,
	metaImage,
	metaSocial,
	canonicalURL,
	keywords,
	metaRobots,
	metaViewport,
	structuredData,
}: SeoFE) => {
	return (
		<Helmet>
			{metaTitle && <title>{metaTitle}</title>}

			{metaDescription && <meta name="description" content={metaDescription} />}

			{canonicalURL ? (
				<link rel="canonical" href={canonicalURL} />
			) : (
				<link rel="canonical" href={`${window.location.href}`} />
			)}

			{keywords && <meta name="keywords" content={keywords} />}

			{metaImage?.url && <meta itemProp="image" content={metaImage?.url} />}

			{metaRobots ? (
				<meta name="robots" content={metaRobots} />
			) : (
				<meta name="robots" content="index, follow" />
			)}

			{metaViewport && <meta name="viewport" content={metaViewport} />}

			{/* Tag for FACEBOOK share */}
			{metaSocial?.fb?.title && (
				<meta property="og:title" content={metaSocial?.fb?.title} />
			)}

			{metaSocial?.fb?.description && (
				<meta property="og:description" content={metaSocial?.fb?.description} />
			)}

			{metaSocial?.fb?.image?.url ? (
				<meta property="og:image" content={metaSocial?.fb?.image?.url} />
			) : (
				<meta property="og:image" content={metaImage?.url} />
			)}

			{canonicalURL ? (
				<meta property="og:url" content={canonicalURL} />
			) : (
				<meta property="og:url" content={`${window.location.href}`} />
			)}

			<meta property="og:type" content="website" />

			{/* Tag for TWITTER share */}
			<meta name="twitter:card" content="summary_large_image" />

			{metaSocial?.twitter?.title && (
				<meta name="twitter:title" content={metaSocial?.twitter?.title} />
			)}

			{metaSocial?.twitter?.description && (
				<meta
					name="twitter:description"
					content={metaSocial?.twitter?.description}
				/>
			)}

			{metaSocial?.twitter?.image?.url ? (
				<meta name="twitter:image" content={metaSocial?.twitter?.image?.url} />
			) : (
				<meta name="twitter:image" content={metaImage?.url} />
			)}

			{canonicalURL ? (
				<meta name="twitter:url" content={canonicalURL} />
			) : (
				<meta name="twitter:url" content={`${window.location.href}`} />
			)}

			{structuredData && (
				<script type="application/ld+json">{structuredData}</script>
			)}
		</Helmet>
	);
};

export default SeoComponent;
