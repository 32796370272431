import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { NotificationContext } from "providers/notificationProvider";

import SeoDataHandler from "utils/SeoDataHandler";
import CaseStudiesDataHandler from "utils/CaseStudiesDataHandler";

import { ClientIndustriesDataBE, CaseStudyTypeBE, Image, ImageFE, ToolsListBE, CaseStudyBannerFE, CaseStudyListBE, SeoBE, SeoFE } from "types/SharedType";

import CaseStudyApi from "api/CaseStudyApi";
import SharedApi from "api/SharedApi";

import RichTextTransformCmp from "components/common/richTextTransformCmp";
import Nav from "components/layout/nav";
import Footer from "components/layout/footer";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import CaseStudyBanner from "./components/caseStudyBanner";
import CaseStudyMainContent from "./components/caseStudyMainContent";
import CaseStudyDescription from "./components/caseStudyDescription";
import CaseStudyPictureList from "./components/caseStudyPictureList";
import TailoredSolution from "./components/tailoredSolution";
import CaseStudyTestimonies from "./components/caseStudyTestimonies";
import CaseStudyCaseStudiesList from "./components/caseStudyCaseStudiesList";

interface CaseStudyDataTypeBE {
	seo: SeoBE;
	title: string;
	banner_image: Image;
	show_banner_image: boolean;
	challenge: string;
	confidentiality: boolean;
	description: string;
	locale: string;
	images: {
		data:{
			attributes: {
				alternativeText: string;
				caption: string;
				url: string;
			}
		}[]
	}
	result: string;
	solution: string;
	case_study_types: {
		data: CaseStudyTypeBE[];
	}
	client:{
		data:{
			id: number;
			attributes:{
				Country: string;
				Name: string;
				client_industries: {
					data: ClientIndustriesDataBE[];
				};
			}
		}
	},
	tools: ToolsListBE;
	localizations: {
		data: {
			id: number;
			attributes: {
				locale: string;
			}
		}[]
	}
}

interface CaseStudyFE {
	title: string;
	bannerImage: ImageFE;
	showBannerImage: boolean;
	challenge: string;
	confidentiality: boolean;
	description: string,
	locale: string;
	images: ImageFE[];
	result: string;
	solution: string;
	caseStudyTypeList: string[];
	client: {
		name: string;
		country: string;
		industry: string[];
	},
	tools: string[];
	localizations: {
		attributes: {
			locale: string;
		}
		id: number;
	}[]
}

const handleCaseStudydata = (caseStudyData: CaseStudyDataTypeBE) => {
	return{
		title: caseStudyData?.title,
		bannerImage: {
			url: `${process.env.REACT_APP_STRAPI_URL}${caseStudyData?.banner_image?.data?.attributes?.url}`,
			caption: caseStudyData?.banner_image?.data?.attributes?.caption,
			alternativeText: caseStudyData?.banner_image?.data?.attributes?.alternativeText
		},
		showBannerImage: caseStudyData?.show_banner_image,
		challenge: caseStudyData?.challenge,
		confidentiality: caseStudyData?.confidentiality,
		description: caseStudyData?.description,
		locale: caseStudyData?.locale,
		images: caseStudyData?.images?.data?.map(image => 
			{
			return{
				url: 
				`${process.env.REACT_APP_STRAPI_URL}${image?.attributes?.url}`,
				caption: image?.attributes?.caption,
				alternativeText: image?.attributes?.alternativeText,
			}
		}
		),
		result: caseStudyData?.result,
		solution: caseStudyData?.solution,
		caseStudyTypeList: caseStudyData?.case_study_types?.data?.map(type => type?.attributes?.name),
		client: {
			name: caseStudyData?.client?.data?.attributes?.Name,
			country: caseStudyData?.client?.data?.attributes?.Country,
			industry: caseStudyData?.client?.data?.attributes?.client_industries?.data?.map((industry) => industry?.attributes?.name)
		},
		tools: caseStudyData?.tools?.data?.map(tool => tool?.attributes?.name),
		localizations: caseStudyData?.localizations?.data,
	}
}

const CaseStudy = () => {
	const {id} = useParams();
	const { t, i18n } = useTranslation('caseStudies');
	const { displayNotification } = useContext(NotificationContext);

	const [seo, setSEO] = useState<SeoFE>();
	const [caseStudy, setCaseStudy] = useState<CaseStudyFE>();
	const [caseStudiesList, setCaseStudiesList] = useState<CaseStudyBannerFE[]>();

	const caseStudiesDataHandler = CaseStudiesDataHandler();

	const sharedApi = SharedApi();
	const caseStudyApi = CaseStudyApi();
	const seoDataHandler = SeoDataHandler();

	const getCaseStudy = async() => {
		try {
			if(id){
				const caseStudyData: CaseStudyDataTypeBE = await caseStudyApi.getCaseStudyData(id, i18n.language);
				const seo: SeoFE = seoDataHandler.handleSeoData(caseStudyData?.seo);
				const caseStudy: CaseStudyFE = handleCaseStudydata(caseStudyData);
	
				seo && setSEO(seo);
				caseStudy &&	setCaseStudy(caseStudy);
			}

		} catch (error) {
			console.log(error);
			displayNotification(`Something Went Wrong When Handling Case Study Data, Please Try Again !`, 'error');
		}
	}

	const getCaseStudiesList = async() => {
		try {

			const caseStudiesData: CaseStudyListBE = await sharedApi.getCaseStudiesData(i18n.language, caseStudy?.caseStudyTypeList, '', 0, id);

			const caseStudiesList: CaseStudyBannerFE[] = caseStudiesDataHandler.handleCaseStudiesData(caseStudiesData?.data);

			caseStudiesList &&	setCaseStudiesList(caseStudiesList);

		} catch (error) {
			console.log(error);
			displayNotification(`Something Went Wrong When Handling Case Study Data, Please Try Again !`, 'error');
		}
	}

	useEffect(() => {
		getCaseStudy();
		getCaseStudiesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, i18n.language])

	return (
		<>

			{ seo && 		
				<SeoComponent
					metaTitle={seo?.metaTitle}
					metaDescription={seo?.metaDescription}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
				/>
			}

			<Nav isBgWhite={true} />

			{caseStudy && 
				<>
					<CaseStudyBanner 
						tag={t('caseStudyTag')}
						title={caseStudy?.title}
						description={caseStudy?.description}
						showBannerImage={caseStudy?.showBannerImage}
					/>

					<CaseStudyMainContent 
						topPicture={caseStudy?.bannerImage?.url}
						info={{
							clientName: caseStudy?.confidentiality ?  undefined : caseStudy?.client?.name,
							industry: caseStudy?.client?.industry,
							caseStudyType: caseStudy?.caseStudyTypeList,
							country: caseStudy?.client?.country,
							tools: caseStudy?.tools,
						}}
						showBannerImage={caseStudy?.showBannerImage}
					>
						<CaseStudyDescription>

							{caseStudy?.challenge && 
								<>							
									<h2>
										{t('challenge')}
									</h2>
									<RichTextTransformCmp>
										{caseStudy?.challenge}
									</RichTextTransformCmp>
								</>
							}

							{caseStudy?.solution &&
								<>								
									<h2>
										{t('solution')}
									</h2>
									<RichTextTransformCmp>
										{caseStudy?.solution}
									</RichTextTransformCmp>
								</>
							}

							{caseStudy?.result &&
								<>							
									<h2>
										{t('result')}
									</h2>
									<RichTextTransformCmp>
										{caseStudy?.result}
									</RichTextTransformCmp>
								</>
							}

						</CaseStudyDescription>

						{caseStudy?.images && caseStudy?.images?.length > 0 &&
							<CaseStudyPictureList 
								list={caseStudy?.images}
							/>
						}

						{caseStudiesList && caseStudiesList?.length > 0 &&						
							<CaseStudyCaseStudiesList
								caseStudyList={caseStudiesList}
							/>
						}

						<CaseStudyTestimonies />

						<TailoredSolution />
					</CaseStudyMainContent>
				</>
			}

			<Footer />
		</>
	);
}

export default CaseStudy;