import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SolutionsListContext } from "providers/solutionsListProvider/SolutionsListProvider";
import { NotificationContext } from "providers/notificationProvider";

import PageDataHandler from "utils/PageDataHandler";

import { PageInfo } from "types/SharedType";

import Nav from "components/layout/nav";

import SeoComponent from "components/common/seoComponent/SeoComponent";
import HeroBanner from "components/layout/heroBanner";
import SolutionsList from "./components/solutionsList/SolutionsList";
import SolutionsTools from "./components/solutionsTools/SolutionsTools";
import Footer from "components/layout/footer";

const Solutions = () => {

	const { getSolutionsList, solutionsList } = useContext(SolutionsListContext);
	const { displayNotification } = useContext(NotificationContext);
	const { i18n } = useTranslation();

	const [pageInfo, setPageInfo] = useState<PageInfo>();
	
	const pageDataHandler = PageDataHandler();
	
	const getPageData = async(pageName: string, locale: string) => {
		try {
			
			const pageInfo = await pageDataHandler.getPageInfo(pageName, locale);

			pageInfo && setPageInfo(pageInfo);

		} catch (error) {
			console.error(`[Error - API] Error calling ${pageName} data`, error);
			displayNotification(`Something Went Wrong Fetching ${pageName} Data, Please Try Again !`, 'error');
		}
	}

	useEffect(() => {
		getSolutionsList();
		getPageData('solutions-page', i18n.language);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18n.language])
	
	return (
		<>
			<Nav />
			
			{pageInfo && 
				<SeoComponent
					metaTitle={pageInfo?.seo?.metaTitle}
					metaDescription={pageInfo?.seo?.metaDescription}
					metaImage={pageInfo?.seo?.metaImage}
					metaSocial={pageInfo?.seo?.metaSocial}
					canonicalURL={pageInfo?.seo?.canonicalURL}
					keywords={pageInfo?.seo?.keywords}
					metaRobots={pageInfo?.seo?.metaRobots}
					metaViewport={pageInfo?.seo?.metaViewport}
					structuredData={pageInfo?.seo?.structuredData}
				/>
			}

			<HeroBanner 
				picture={pageInfo?.detail?.bannerImage?.url || ''}
				title={pageInfo?.detail?.title || ''}
				description={pageInfo?.detail?.description || ''}
			/>

			{solutionsList && solutionsList?.length > 0 &&
				<SolutionsList list={solutionsList} />
			}

			<SolutionsTools />

			<Footer />
		</>
	);
}

export default Solutions;