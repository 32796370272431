import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NotificationContext } from "providers/notificationProvider";

import SeoDataHandler from "utils/SeoDataHandler";
import DetailDataHandler from "utils/DetailDataHandler";
import CaseStudiesDataHandler from "utils/CaseStudiesDataHandler";

import {
	ApproachFE,
	CaseStudyBE,
	CaseStudyBannerFE,
	DevelopmentProcesstypeFE,
	DevelopmentServicesTypeFE,
	DevelopmentStepTypeFE,
	Image,
	PageDetailBE,
	PageDetailFE,
	SeoBE,
	SeoFE,
	ServiceFE,
	ToolsListBE,
	ToolsListFE,
} from "types/SharedType";

import SolutionApi from "api/SolutionApi";

import Nav from "components/layout/nav";
import HeroBanner from "components/layout/heroBanner";
import Footer from "components/layout/footer";
import SimpleText from "components/layout/simpleText";
import GetStarted from "components/layout/getStarted";
import ContactUsCmp from "components/layout/contactUsCmp";

import CollaborativeApproach from "./components/collaborativeApproach";
import Security from "./components/security";
import WhitePaper from "./components/whitePaper";
import ServicesOffered from "./components/servicesOffered";
import ReasonToChooseUs from "./components/reasonToChooseUs";
import SeoComponent from "components/common/seoComponent/SeoComponent";
import SolutionTools from "./components/solutionTools";
import SolutionCaseStudies from "./components/solutionCaseStudies";
import SolutionDevelopmentProcess from "./components/solutionDevelopmentProcess";

import { getColoredText } from "utils/ColoredText";

interface ServiceBE {
	title: string;
	description: string;
	icon: {
		data: {
			attributes: {
				icon: Image;
				createdAt: string;
				publishedAt: string;
				updatedAt: string;
			};
		};
	};
}

interface DevelopmentServicesTypeBE {
	id: number;
	title: string;
	description: string;
	services: ServiceBE[];
}

interface StepBE {
	id: number;
	title: string;
	description: string;
}

interface ApproachStepBE {
	id: number;
	title: string;
	description: string;
}

interface ApproachBE {
	id: number;
	title: string;
	description: string;
	steps: ApproachStepBE[];
}

interface ReasonDataTypeBE {
	id: number;
	label: string;
	description: string;
}

interface ReasonsDataTypeBE {
	id: number;
	title: string;
	list_of_reasons: ReasonDataTypeBE[];
}

interface ReasonDataTypeFE {
	title: string;
	content: string;
}

interface ReasonsDataTypeFE {
	title: string;
	list: ReasonDataTypeFE[];
}

interface SolutionsDataTypeBE {
	seo: SeoBE;
	detail: PageDetailBE;
	introduction_text: string;
	label: string;
	white_paper_detail: {
		description: string;
		subtitle: string;
		title: string;
		id: number;
	};
	development_services: DevelopmentServicesTypeBE;
	development_process: {
		id: number;
		description: string;
		steps: StepBE[];
	};
	selected_case_studies: {
		case_studies: {
			data: CaseStudyBE[];
		};
	};
	tools: ToolsListBE;
	approach: ApproachBE;
	reasons_to_choose_us: ReasonsDataTypeBE;
	security_and_reliability: {
		id: number;
		title: string;
		description: string;
	};
	urlPath: string;
}

interface SolutionsDataTypeFE {
	label: string;
	introductionText: string;
	whitePaperDetail: {
		title: string;
		subtitle: string;
		description: string;
	};
	developmentServices: DevelopmentServicesTypeFE;
	developmentProcess: DevelopmentProcesstypeFE;
	approach: ApproachFE;
	reasonsList: ReasonsDataTypeFE;
	securityAndReliability: {
		title: string;
		description: string;
	};
}

const handleToolsList = (tools: ToolsListBE) => {
	const toolsList: ToolsListFE = tools?.data
		?.filter(
			(item) =>
				item.attributes.logo?.logo?.data !== undefined &&
				item.attributes.logo?.logo?.data !== null
		)
		.map((tool) => {
			return {
				logo: {
					url: `${process.env.REACT_APP_STRAPI_URL}${tool?.attributes?.logo?.logo?.data?.attributes?.url}`,
					caption: tool?.attributes?.logo?.logo?.data?.attributes?.caption,
					alternativeText:
						tool?.attributes?.logo?.logo?.data?.attributes?.alternativeText,
				},
				name: tool?.attributes?.name,
			};
		});

	return toolsList;
};

const handleSolutionData = (solutionsData: SolutionsDataTypeBE) => {
	const seriveListData: ServiceFE[] =
		solutionsData?.development_services?.services?.map((service) => {
			return {
				title: service?.title,
				description: service?.description,
				icon: {
					name: service?.icon?.data?.attributes?.icon?.data?.attributes?.name,
					alternativeText:
						service?.icon?.data?.attributes?.icon?.data?.attributes
							?.alternativeText,
					caption:
						service?.icon?.data?.attributes?.icon?.data?.attributes?.caption,
					url: `${process.env.REACT_APP_STRAPI_URL}${service?.icon?.data?.attributes?.icon?.data?.attributes?.url}`,
				},
			};
		});

	const stepListData: DevelopmentStepTypeFE[] =
		solutionsData?.development_process?.steps.map((step, index) => {
			return {
				tag: index + 1 + "",
				title: step?.title,
				description: step?.description,
			};
		});

	const whitePaperDetail = {
		title: solutionsData?.white_paper_detail?.title,
		subtitle: solutionsData?.white_paper_detail?.subtitle,
		description: solutionsData?.white_paper_detail?.description,
	};

	const developmentServices: DevelopmentServicesTypeFE = {
		title: solutionsData?.development_services?.title,
		description: solutionsData?.development_services?.description,
		serviceList: seriveListData,
	};

	const developmentProcess: DevelopmentProcesstypeFE = {
		descipriton: solutionsData?.development_process?.description,
		stepList: stepListData,
	};

	const approach: ApproachFE = {
		title: solutionsData?.approach?.title,
		description: solutionsData?.approach?.description,
		stepsList: solutionsData?.approach?.steps?.map((step, index) => {
			return {
				key: index + 1,
				title: step?.title,
				description: step?.description,
			};
		}),
	};

	const reasonsList = {
		title: solutionsData?.reasons_to_choose_us?.title,
		list: solutionsData?.reasons_to_choose_us?.list_of_reasons?.map(
			(reason) => {
				return {
					title: reason?.label,
					content: reason?.description,
				};
			}
		),
	};

	const securityAndReliability = {
		title: solutionsData?.security_and_reliability?.title,
		description: solutionsData?.security_and_reliability?.description,
	};

	return {
		label: solutionsData?.label,
		introductionText: solutionsData?.introduction_text,
		whitePaperDetail: whitePaperDetail,
		developmentServices: developmentServices,
		developmentProcess: developmentProcess,
		approach: approach,
		reasonsList: reasonsList,
		securityAndReliability: securityAndReliability,
	};
};

const Solution = () => {
	const { i18n } = useTranslation("solutions");
	const { displayNotification } = useContext(NotificationContext);
	const { id } = useParams();

	const [seo, setSeo] = useState<SeoFE>();
	const [detail, setDetail] = useState<PageDetailFE>();
	const [solution, setSolution] = useState<SolutionsDataTypeFE>();
	const [toolsList, setToolsList] = useState<ToolsListFE>([]);
	const [paginatedList, setPaginatedList] = useState<ToolsListFE>([]);
	const [selectedCaseStudies, setSelectedCaseStudies] = useState<
		CaseStudyBannerFE[]
	>([]);

	const solutionApi = SolutionApi();
	const seoDataHandler = SeoDataHandler();
	const detailDataHandler = DetailDataHandler();
	const caseStudiesDataHabdler = CaseStudiesDataHandler();

	const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

	const currentPage = useRef(0);

	const loadMore = () => {
		currentPage.current = currentPage.current + 1;
		handlePagination(currentPage.current);
	};

	const handlePagination = (pageNum = 0) => {
		const expectedNumOfTools = (currentPage.current + 1) * 7;
		const totalNumOfTools = toolsList?.length;

		if (expectedNumOfTools < totalNumOfTools) {
			setCanLoadMore(true);
		} else {
			setCanLoadMore(false);
		}

		if (toolsList) {
			const paginatedToolsList = toolsList?.slice(0, (pageNum + 1) * 7);
			setPaginatedList(paginatedToolsList);
		}
	};

	const getSolution = async () => {
		try {
			const solutionData: SolutionsDataTypeBE =
				await solutionApi.getSolutionData(i18n.language, id);

			const seoFE: SeoFE = seoDataHandler.handleSeoData(solutionData?.seo);
			const detailFE: PageDetailFE = detailDataHandler.handleDetailData(
				solutionData?.detail
			);
			const solutionFE: SolutionsDataTypeFE = handleSolutionData(solutionData);
			const toolsList: ToolsListFE = handleToolsList(solutionData?.tools);
			const caseStudiesListFE: CaseStudyBannerFE[] =
				caseStudiesDataHabdler.handleCaseStudiesData(
					solutionData?.selected_case_studies?.case_studies?.data
				);

			seoFE && setSeo(seoFE);
			detailFE && setDetail(detailFE);
			solutionFE && setSolution(solutionFE);
			toolsList && setToolsList(toolsList);
			caseStudiesListFE && setSelectedCaseStudies(caseStudiesListFE);
		} catch (error) {
			console.log(error);
			displayNotification(
				"Something Went Wrong When Handling Solutions Data, Please Try Again !",
				"error"
			);
		}
	};

	useEffect(() => {
		getSolution();
		// eslint-disable-next-line
	}, [id, i18n.language]);

	useEffect(() => {
		currentPage.current = 0;
		handlePagination(0);
		// eslint-disable-next-line
	}, [toolsList]);

	return (
		<>
			<Nav />

			{seo && (
				<SeoComponent
					metaTitle={seo?.metaTitle}
					metaDescription={seo?.metaDescription}
					metaImage={seo?.metaImage}
					metaSocial={seo?.metaSocial}
					canonicalURL={seo?.canonicalURL}
					keywords={seo?.keywords}
					metaRobots={seo?.metaRobots}
					metaViewport={seo?.metaViewport}
					structuredData={seo?.structuredData}
				/>
			)}

			<HeroBanner
				picture={detail?.bannerImage?.url || ""}
				title={detail?.title || ""}
				description={detail?.description || ""}
			/>

			{solution && (
				<>
					<SimpleText withBubbles={true}>
						{getColoredText(solution?.introductionText)}
					</SimpleText>

					<ServicesOffered
						title={solution?.developmentServices?.title}
						description={solution?.developmentServices?.description}
						serviceList={solution?.developmentServices?.serviceList}
					/>
				</>
			)}

			<ContactUsCmp />

			{solution && (
				<>
					<SolutionDevelopmentProcess
						description={solution?.developmentProcess?.descipriton}
						list={solution?.developmentProcess?.stepList}
					/>

					<SolutionTools
						label={solution?.label}
						list={paginatedList}
						canLoadMore={canLoadMore}
						loadMore={loadMore}
					/>
				</>
			)}

			<SolutionCaseStudies list={selectedCaseStudies} />

			{solution && (
				<>
					<CollaborativeApproach
						title={solution?.approach?.title}
						description={solution?.approach?.description}
						stepsList={solution?.approach?.stepsList}
					/>

					<Security
						title={solution?.securityAndReliability?.title}
						description={solution?.securityAndReliability?.description}
					/>

					<ReasonToChooseUs
						title={solution?.reasonsList?.title}
						list={solution?.reasonsList?.list}
					/>
				</>
			)}

			<GetStarted />

			{solution && (
				<WhitePaper
					title={solution?.whitePaperDetail?.title}
					subtitle={solution?.whitePaperDetail?.subtitle}
					id={id || ""}
				>
					{solution?.whitePaperDetail?.description}
				</WhitePaper>
			)}

			<Footer />
		</>
	);
};

export default Solution;
